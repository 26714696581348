@import "src/sass/utils";

.SettingsScreen {
  padding-left: var(--app-padding-left);
  padding-right: var(--app-padding-right);

  svg {
    display: block;
  }
}

.SettingsScreen__title_container {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
}

.SettingsScreen__title {
  opacity: 0.87;
  font-size: 4.375rem;
  font-weight: 900;
  line-height: 1.01;
}

.SettingsScreen__back {
  font-size: 30px;
  margin-right: 10px;
  cursor: pointer;
  color: var(--primary-color);
}

.SettingsScreen__wrapper {
  display: grid;
  grid-template-columns: 1fr 2.7fr;
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  padding-top: 2rem;

  .menu {
    grid-column: 1;
    grid-row: 1;
  }
  .view {
    grid-column: 2 / 5;
    grid-row: 1;
  }

  @include desktopDown {
    grid-template-columns: 1fr;

    .menu {
      grid-column: 1;
      grid-row: 1;
    }
    .view {
      grid-column: 1;
      grid-row: 1;
    }
  }
}
