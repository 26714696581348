@import "src/sass/variables";
@import "../../Select/Select.scss";

.UsersBrowse {
    padding-top: 16px;

    .SelectedUser {
        position: relative;
        min-width: 200px;
        border: $select-border;
        border-radius: 34px;
        display: inline-block;
        background-color: $input-bg;
        margin: 0 0 22px 0;
        padding: 0;

        input {
            cursor: pointer;
            padding:  2 * $select-padding-vertical 2 * $select-padding-horizontal;
            border: none;
            border-radius: 34px;
            // color: $select-color;
            font-size: $select-font-size;
            letter-spacing: $select-letter-spacing;
            text-shadow: $select-text-shadow;
            width: calc(100% - (32px + 2*4px));
            color: $primary
        }
        input:focus {
            outline: none;
        }

        .SearchIcon {
            position: absolute;
            display: flex;
            padding-right: 12px;
            right: 0;
            top: 0;
            pointer-events: none;
  
            height: 100%;
            align-items: center;
            justify-content: center;
            svg {
                display: block;
                height: 50%;
                width: 32px;
                fill: var(--primary-color);
            }
        }
    }
}