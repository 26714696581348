@import "src/sass/utils";

.ListComponentArrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  background: rgba(#000, 0.7);
  top: 0;

  @include touch-screen {
    display: none;
  }

  svg {
    font-size: 20px;
  }

  &--left {
    z-index: 5;
    cursor: pointer;
    left: 0;
  }

  &--right {
    z-index: 5;
    cursor: pointer;
    right: 0;
  }

  &.disabled {
    display: none;
  }
}
