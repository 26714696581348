.AudioPlayerProgressBar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  width: 100%;

  &__progress {
    width: 80%;
    padding: 0;
    height: auto;
    background: transparent;

    input {
      -webkit-appearance: none;
      margin: auto;
      height: 7px;
      background: rgba(217, 217, 217, 0.3);
      border-radius: 5px;
      background-image: linear-gradient(var(--primary-color),
          var(--primary-color));
      background-repeat: no-repeat;
      cursor: pointer;

      &::-webkit-slider-thumb {
        cursor: pointer;
        -webkit-appearance: none;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: linear-gradient(var(--primary-color), var(--primary-color));
        //cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
      }
    }
  }

  &__time {
    width: 10%;
    margin: auto;
    text-align: end;

    &--left {
      text-align: end;
      padding-right: 5px;
    }

    &--right {
      text-align: start;
      padding-left: 5px;
    }
  }
}