@import "src/sass/utils";

.HamburgerMenu {
  display: flex;
  position: relative;
  -webkit-user-select: none;
  user-select: none;

  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0;
    /* hide this */
    z-index: 2;
    /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: var(--primary-text-color);
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      opacity 0.55s ease;

    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      transform-origin: 0% 100%;
      margin-bottom: 0;
    }
  }

  input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: var(--primary-color);
  }

  input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  input:checked~span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  input:checked~div {
    transform: none;
  }

  &__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    box-sizing: border-box;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;

    background: var(--header-bg-color);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  }

  &__Items {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    font-size: 3rem;
    font-weight: bold;
  }

  &__Logo {
    height: 7rem;
  }
}