.SearchScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__InputContainer {
    position: relative;
    height: 15vh;
  }

  &__Loader {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
  }

  &__NoResults {
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
  }

  section {
    flex: 1;
    padding-left: var(--app-padding-left);
    padding-right: var(--app-padding-right);
  }
}
