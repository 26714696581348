@import "src/sass/utils";
@import "src/sass/variables";

.LoginCodeContainer {
  max-width: 400px;

  .LoginCodeInputs {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .LoginCodeLabel {
      margin: 100px 8.5px 64px 8.5px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      .LoginCodeInput {
        height: 78px;
        min-width: 63px;
        padding: 2px;
        text-align: center;
        background-color: transparent;

        input {
          text-align: center;
          font-size: 43px;
          color: $primary-text;
          text-transform: uppercase;
          border: solid 1px var(--primary-color);
          border-radius: 6px;
          background-color: #222;
          &:focus {
            border: solid 1px $primary-text;
          }
        }
      }
    }
  }

  .LoginCodeResponse {
    font-size: 16px;

    &__message {
      margin-left: 5px;
    }

    &__error {
      color: $error-color;
    }

    &__success {
      color: $success-color;
    }
  }
}

.LoginCodeButton {
  width: 100%;
  margin: 0;
  justify-content: center;
}

@include phone {
  .LoginCodeContainer {
    .LoginCodeInputs {
      .LoginCodeLabel {
        margin: 119px 4px 64px 4px;
        .LoginCodeInput {
          height: 55px;
          min-width: 35px;
          input {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@include tablet {
  .LoginCodeContainer {
    .LoginCodeInputs {
      .LoginCodeLabel {
        margin: 119px 4px 64px 4px;
        .LoginCodeInput {
          height: 60px;
          min-width: 40px;
          input {
            font-size: 28px;
          }
        }
      }
    }
  }
}
