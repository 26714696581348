@import "src/sass/variables";

.VolumeButton {
  margin: 0;
  width: 2vh;
  height: 2vh;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;

  &__slider {
    position: relative;
    width: 12vh;
    padding: 10px;
    transform: rotate(270deg);
    height: 30px;
    bottom: -25px;
    right: 4vh;
    background: var(--bg-color);

    [dir="rtl"] & {
      left: 4vh;
      right: auto;
    }
  }

  &__overlay {
    width: 30px;
    height: 12vh;
    padding: 0;

    .rc-tooltip-inner {
      width: 30px;
      height: 12vh;
      padding: 0;
      background-color: transparent;
      background: transparent;
      accent-color: var(--primary-color);
    }
  }

  .MediaButton__text {
    padding: 0 !important;
  }
}
