@import "src/sass/utils";

.DesktopHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 60px;
  padding-left: var(--app-padding-left);
  padding-right: var(--app-padding-left); //Because padding right is different 
  background: linear-gradient(to bottom, var(--header-bg-color), transparent);
  z-index: 1;
  @include prevent-tab-highlights;

  &__Left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 auto;
  }

  &__Right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 180px;

    @include largeDown {
      flex-basis: 140px;
    }
  }

  @include tabletDown {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: calc(var(--app-padding-left) / 2);
    padding-right: calc(var(--app-padding-right) / 2);
  }
}