@import "src/sass/variables";
@import "src/sass/utils";

.UploadAssistant {
  position: fixed;
  bottom: 0;
  right: 35px;
  z-index: 1000;
  width: 400px !important;
  height: unset !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  background: var(--bg-color);
  border: 1px solid var(--primary-color-d-50);
  border-bottom: none;

  @include phone-portrait {
    left: 10px;
    right: 15px;
    width: calc(100% - 25px) !important;
  }

  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: var(--primary-color);
    font-weight: 700;
    font-size: 18px;

    span {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &__Colapse-Button {
      cursor: pointer;

      svg {
        width: 40px;
      }
    }
  }

  &__Content {
    max-height: 320px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-inline: 20px;
    padding-bottom: 25px;

    transition: height 400ms ease-in-out;

    &.collapsed {
      height: 0;
      padding-bottom: 0;
    }

    &__Warning {
      border: 1px solid var(--warning-color);
      color: var(--warning-color);
      border-radius: 10px;
      padding: 10px;
      display: flex;
      margin-bottom: 20px;

      svg {
        width: 40px;
      }
    }

    &__Item {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-block: 15px;
      font-size: 16px;

      p {
        @include ellipsis();
        @include line-clamp-ellipsis(1);
        padding: 0;
        margin: 0;

      }
    }
  }
}
