@import "src/sass/utils";
$category-drawer-padding: 0 40px 0 70px;
$category-drawer-padding-phone: 0 24px 0 28px;

.MediaCategoryDrawer {
  .drawer-body {
    padding: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
  }

  &__header {
    opacity: 0.87;
    font-size: 2.125rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.28px;
    flex: 0 auto;
    margin-bottom: 15px;
    text-transform: uppercase;
    padding: $category-drawer-padding;
  }

  &__trigger {
    display: inline-flex;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;

    &__icon {
      font-size: 1.75rem;
      margin-right: 14px;

      [dir="rtl"] & {
        margin-left: 14px;
      }

      svg {
        display: block;
      }
    }

    &__text {
      font-size: 0.875rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.6px;

      @include phone {
        font-size: 1.75rem;
      }
    }
  }
}

.MediaCategoryList {
  overflow: auto;
  padding: $category-drawer-padding;

  @include phone {
    padding: $category-drawer-padding-phone;
  }

  &__more {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.MediaCategory {
  display: inline-block;
  opacity: 0.87;
  font-size: 1.1875rem;
  font-weight: 400;
  letter-spacing: 0.9px;
  padding: 15px 0;
  padding-right: 50px;
  cursor: pointer;
  transition: all 0.2s;
  width: 50%;
  box-sizing: border-box;

  @include phone {
    font-size: 2rem;
    padding-right: 28px;
  }

  &--active {
    font-weight: 700;
    position: relative;
  }

  &:hover {
    color: var(--primary-color);
  }

  &__indicator {
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--primary-color);
  }

  &__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}