@import "src/sass/utils";

.DetectorScreen {
  width: 100%;

  h2 {
    font-size: 22px;
  }

  &__Container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-inline: 50px;

    @include tabletDown {
      flex-direction: column;
    }

    &__Uploader {
      width: 350px;
      flex-shrink: 0;

      @include tabletDown {
        width: 100%;
      }
    }

    &__Results {
      flex-grow: 1;
    }
  }
}
