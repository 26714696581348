@import "src/sass/utils";
@import "src/sass/variables";


.EmbedderCreator {
  padding-left: var(--app-padding-left);
  padding-right: var(--app-padding-right);
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  h1 {
    margin-top: 0;
  }

  .rc-steps {
    margin-block: 20px;
  }

  .LabelField {
    align-items: flex-start;

    .rc-textarea {
      box-sizing: border-box;
      padding: 0;
      font-family: inherit;

      &:focus {
        outline: none;
      }
    }
  }

  .WizardSpinner {
    display: flex;
    justify-content: center;
  }
}
