@import "src/sass/variables";

.AddAssetForm {
  display: flex;
  flex-direction: column;

  .LabelField .Label {
    margin: 0;
  }

  .Select {
    width: 100%;
  }

  .upload {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
}
