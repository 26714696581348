@import "src/sass/variables";
@import "src/sass/utils";

.SettingsProfile-wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 5em 1em;
  grid-auto-rows: minmax(100px, auto);
  align-items: start;

  .contactsDescription {
    grid-column: 1;
    grid-row: 1;
    opacity: 0.87;
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;

    @include phone {
      font-size: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    p {
      font-size: 1em;
      font-weight: normal;
      line-height: 28px;
      letter-spacing: 1.38px;
    }

    .contactsUserInfo {
      display: flex;
      align-items: flex-start;
      padding-bottom: 1em;
      flex-direction: column;
      min-height: 7em;

      .circular {
        display: inline-block;
        position: relative;
        width: 5em;
        height: 5em;
        overflow: hidden;
        border-radius: 50%;
        text-align: center;

        &--error {
          border: 2px solid $error-color;
        }
      }

      .Error {
        font-size: 12px;
        font-weight: 300;
        color: $error-color;
        list-style: none;
        padding: 0;
        text-align: left;
        width: 100%;
      }

      .Avatar {
        height: 5em;
        width: 5em;
        max-width: 100%;
        max-height: 100%;

        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
  }

  .contactsForm {
    grid-column: 2;
    grid-row: 1;
  }

  .passwordDescription {
    grid-column: 1;
    grid-row: 2;
    opacity: 0.87;
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;

    @include phone {
      font-size: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    p {
      font-size: 1em;
      font-weight: normal;
      line-height: 28px;
      letter-spacing: 1.38px;
      white-space: pre-wrap;
    }
  }

  .passwordForm {
    grid-column: 2;
    grid-row: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &__button {
      font-size: 1.5rem;
      letter-spacing: 0.9px;
      cursor: pointer;
    }
  }
}


.deleteAccountContainer {
  text-align: center;

  &__buttonDelete {
    font-size: 1.5rem;
    letter-spacing: 0.9px;
    cursor: pointer;
  }
}

.link {
  opacity: 0.8;
}

.underline {
  text-decoration: underline;
}

@include tabletDown {
  .SettingsProfile-wrapper {
    grid-template-columns: 1fr;

    .contactsDescription {
      grid-column: 1;
      grid-row: 1;
    }

    .contactsForm {
      grid-column: 1;
      grid-row: 2;
    }

    .passwordDescription {
      grid-column: 1;
      grid-row: 3;
    }

    .passwordForm {
      grid-column: 1;
      grid-row: 4;
    }
  }
}
