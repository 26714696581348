@import "src/sass/utils";

.DeleteAccountScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

 &__info {
   text-align: center;
   padding: 0 10px;
 }
  .MediaButton {
    border-radius: 70px;
  }

  h1 {
    font-size: 42px;
    line-height: 47px;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 20px;
    font-size: $h1-font-size;

    @include phone {
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    line-height: 25px;
    margin-top: 12px;

    @include phone {
      font-size: 14px;
    }
  }

  &__backButton {
    text-align: center;
    margin-top: 46px;
    font-size: 16px;
    line-height: 14px;
  }

  form,
  .form {
    width: 50%;

    @include large {
      width: 55%;
    }

    @include tablet {
      width: 65%;
    }

    @include phone {
      width: 90%;
    }
  }
}
