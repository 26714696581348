.SettingsAbout {
  display: flex;
  flex-direction: column;

  &__option {
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.9px;
    margin: 20px 0;
  }

  span {
    font-size: 1em;
    font-weight: normal;
    line-height: 28px;
    letter-spacing: 1.38px;
  }
}
