@import "src/sass/variables";

.PodcastDetailsCard {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 15px;
  margin: 10px;
  border-radius: 8px;
  background-color: rgba(196, 196, 196, 0.1);

  &__details {
    display: flex;
    flex-direction: column;
    margin: 0 2vw;
    justify-content: space-around;
    align-items: flex-start;
    font-size: 1.4vh;
    width: 70%;
  }

  &__image {
    margin: auto 0 auto 0;
    height: 14vh;
    width: 14vh;

    path {
      fill: currentColor;
    }

    * {
      border-radius: 12px;
    }
  }

  &__a {
    margin: auto;
  } 

  &__button {
    margin: auto;

    background-color: rgba(196, 196, 196, 0.2);

    &--isPlaying {
      border-color: var(--primary-color);
      background-color: rgba(74, 144, 226, 0.3);
    }
  }

  &__description {
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    display: -webkit-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
  }

  &__text {
    font-size: 1.4em;

    &--small {
      font-size: 1em;
    }
  }

  &--active {
    background: rgba(196, 196, 196, 0.2);
  }
}