@import "src/sass/utils";
@import "src/sass/variables";

.ListComponentItemHeroLandscape {
  position: relative;
  transition: all 0.2s;
  aspect-ratio: 6.07/1;
  border-radius: 5px;
  background-color: var(--cell-background-color);
  cursor: pointer;

  &-container {
    aspect-ratio: 6.07/1;
    border-radius: 5px;
    &--skeleton {
      background: var(--cell-background-color);
    }
  }
  &-image-container {
    aspect-ratio: 6.07/1;
    border-radius: 5px;
    height: 100%;
  }
  &-image {
    width: 100%;
    height: 100%;
    img {
      border-radius: 5px;
      object-fit: cover;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.93)
    );
  }

  &-skeleton {
    border-radius: 5px;
    position: absolute;
    width: 100%;
    bottom: 26px;
    left: 20px;
  }

  &:hover {
    .ListComponentItemHeroLandscape__overlay {
      opacity: 1;
    }
  }
}
