@import "src/sass/utils";
@import "src/sass/variables";

// TODO most of the styles are shared across MovieDetails,
// SeriesDetails, PackageDetails so it might be a good idea to
// create a shared definition
.SeriesDetails {
  &__info {
    width: 50%;
    padding-top: 150px;
    padding-left: 200px;

    [dir="rtl"] & {
      padding-right: 200px;
      padding-left: 0;
    }

    @include phone {
      width: 90%;
    }

    @include tablet {
      width: 80%;
    }

    @include tabletDown {
      padding-top: 0;
      padding-left: var(--app-padding-left);

      [dir="rtl"] & {
        padding-right: var(--app-padding-left);
      }
    }

    @include desktop {
      padding-top: 80px;
      padding-left: 150px;
      width: 80%;

      [dir="rtl"] & {
        padding-right: 150px;
      }
    }
  }

  &__title {
    opacity: 0.87;
    font-size: 4rem;
    font-weight: 900;
    line-height: 1.01;
    text-transform: uppercase;

    @include line-clamp-ellipsis(2);
  }

  .MediaAdditionalInfo {
    margin: 10px 0;
  }

  &__episode {
    opacity: 0.87;
    font-size: 1.5rem;
    letter-spacing: 0.75px;
    margin-bottom: 13px;
  }

  &__episode-number {
    font-weight: 600;
  }

  &__episode-list {
    padding-top: 50px !important;
  }

  &__actions {
    display: flex;
    font-size: 1rem;
    letter-spacing: 0.0375rem;
    margin: 0 0 44px;

    @include tabletDown {
      flex-wrap: wrap;
    }
  }

  &__description {
    opacity: 0.87;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: 0.0425rem;
    margin: 44px 0 48px;
    width: 75%;

    img {
      width: 100%;
      height: auto;
    }

    @include tabletDown {
      margin: 22px 0 24px;
    }
  }

  &__options {
    margin-left: var(--app-padding-left);
    margin-right: var(--app-padding-right);
    margin-top: 20px;
  }

  .Rating {
    font-size: 20px;
  }
}
