@import "src/sass/utils";

.AudioInfo {
  position: relative;
  padding-left: 1vw;
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  &__trailer-text {
    font-size: 1vh;
  }

  &__texts {
    display: flex;
    flex-direction: column;
    font-size: 1.8vh;
    gap: 1vh;
    max-width: 100%;

    &--title {
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--details {
      font-size: 1.5vh;
      color: #BDBDBD;
    }
  }

  &__image {
    cursor: pointer;
    margin: auto 0 auto 0;
    width: 8vh;
    width: 8vh;

    path {
      fill: currentColor;
    }


    * {
      border-radius: 5px;
    }
  }
}