@import "src/sass/variables";

.Slider {
  --progress-position: 0;
  --ticknes: 6px;
  --thumb-scale: 250%;

  cursor: pointer;
  align-items: center;

  &.horizontal {
    width: 100%;
    height: var(--ticknes);
  }

  &.vertical {
    width: var(--ticknes);
    height: 100%;
  }

  &.read-only {
    cursor: default;
  }

  &.read-only &__Progress__ThumbIndicator {
    display: none;
  }

  &__Progress {
    position: relative;
    width: 100%;
    height: 100%;
    background: $slider-progress-color;
    border-radius: 3px;

    &:after {
      content: "";
      position: absolute;
      background-color: var(--primary-color);
      border-radius: 3px;
    }

    &.horizontal {
      &:after {
        left: 0;
        top: 0;
        bottom: 0;
        right: calc(100% - var(--progress-position) * 100%);
      }
    }

    &.vertical {
      &:after {
        left: 0;
        right: 0;
        top: calc(100% - var(--progress-position) * 100%);
        bottom: 0;
      }
    }

    &__ThumbIndicator {
      position: absolute;
      background-color: var(--primary-color);
      border-radius: 50%;
      transition: transform 150ms ease-in-out;
      aspect-ratio: 1 / 1;

      &.horizontal {
        height: var(--thumb-scale);
        left: calc(var(--progress-position) * 100%);
        top: -66%;
        transform: translateX(-50%);

        &:hover {
          transform: translateX(-50%) scale(1.2);
        }
      }

      &.vertical {
        width: var(--thumb-scale);
        bottom: calc(var(--progress-position) * 100%);
        left: -66%;
        transform: translateY(50%);

        &:hover {
          transform: translateY(50%) scale(1.2);
        }
      }
    }
  }
}
