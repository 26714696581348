@import "src/sass/utils";

.Epg {
  * {
    box-sizing: border-box;
  }

  &-header {
    width: 100%;
    display: table;

    &-options {
      display: table-cell;
      width: 100%;
      vertical-align: middle;
    }

    &-info {
      display: table-cell;
      vertical-align: middle;
      font-size: 46px;
      font-weight: 500;
      line-height: 46px;
      text-align: right;
      width: auto;
      white-space: nowrap;
      padding-right: 80px;

      &-title {
        padding-left: 30px;
        display: inline-block;
      }
    }

    & &-button {
      display: flex;
      justify-content: center;
      width: 220px;
      height: 74px;
      border-radius: 4px;
      font-size: 30px;
      font-weight: 600;
      line-height: 37px;
      text-align: center;
      margin: 0;
    }
  }

  &-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
