@import "src/sass/utils";
@import "src/sass/variables";

.EpisodesList {
  &__album {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-left: 200px;
    box-sizing: border-box;

    @include tabletDown {
      padding-top: 0;
      padding-left: var(--app-padding-left);
    }

    @include desktop {
      padding-left: 150px;
    }

    @include phone {
      padding: 0 var(--app-padding-right);
      width: 100%;
    }
  }

  &__select {
    width: 30%;
    margin-left: 69%;
  }

  &__title {
    padding: 20px;
    font-size: 2rem;
    font-weight: 700;
  }
}