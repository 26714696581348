.SettingsMenuItem {
  opacity: 0.87;
  font-size: 2.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.28px;
  margin: 0.7em 0;
  list-style-type: none;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
  min-height: 41px;

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    color: var(--primary-color);
  }

  &--active {
    font-weight: bold;

    &__indicator {
      position: absolute;
      top: 50%;
      left: -1em;
      [dir="rtl"] & {
        right: -1em;
      }
      transform: translate(-50%, -50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: var(--primary-color);
    }
  }

  &--empty {
    cursor: default;
    pointer-events: none;
  }
}
