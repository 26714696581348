.ListComponentItemCover {
  margin: 0;
  padding: 0;

  &__container {
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
  }

  &__skeleton {
    border-radius: 5px;
    background: var(--cell-background-color);
    padding-top: 133.33%;
  }

  &__overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.93)
    );
  }

  &__image-container {
    position: relative;
    padding-top: 133.33%;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: absolute;
    bottom: 0;
    right: 2%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
  }

  &__info-trigger {
    transition: all 0.2s;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 1;
  }

  &__info-trigger,
  &__play-button {
    opacity: 0;
  }

  &:hover {
    .ListComponentItemCover__overlay,
    .ListComponentItemCover__info-trigger,
    .ListComponentItemCover__play-button {
      opacity: 1;
    }
  }

  &__info {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.2s;
    opacity: 0;

    &--visible {
      opacity: 1;
    }
  }

  .ProgressBar-container {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.11) !important;
    height: 7px;

    .ProgressBar-bar {
      border-radius: 3.5px;
    }
  }
}
