.Input {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 54px;
  margin: 0;
  padding: 20px 30px 20px 50px;

  border: none;
  border-radius: 34px;
  background-color: var(--cell-background-color);

  &__input {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;

    font-size: 1rem;
    letter-spacing: 0.6px;
    background: transparent;
    color: var(--primary-text-color);
    list-style: none;
    text-align: start;

    border: none;

    &:focus {
      outline: 0;
    }
  }

  &__suffix {
    display: flex;
    align-items: center;
  }

  &__clear,
  &__icon {
    width: 28px;
    height: 28px;
    font-size: 28px;
    margin-left: 8px;

    img {
      width: 100%;
    }

    svg {
      display: block;
    }
  }

  &__clear {
    cursor: pointer;

    &--hidden {
      visibility: hidden;
    }
  }
}
