@import "src/sass/variables";

.RegisterSuccess,
.RegisterSuccessCustom {
  h1 {
    font-size: $h1-font-size;
    margin-bottom: -5px;
  }

  &__subtitle {
    font-size: 18px;
    margin-top: 35px;
  }
}

.RegisterSuccessCustom {
  &__subtitle {
    font-size: 18px;
    margin-top: 35px;
  }
}
