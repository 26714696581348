.LinkScreen {
  &__section {
    padding-left: var(--app-padding-left);
    padding-right: var(--app-padding-right);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__heading {
    font-size: 2.125em;
    margin-bottom: 24px;
  }

  &__desc {
    font-size: 1.5em;
  }

  > .AppFooter__Line {
    margin: 120px var(--app-padding-right) 0;
  }

  > .ListComponentHorizontal {
    padding-top: 0;
  }
}
