@import "src/sass/utils";

.Summary {
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;

  @include tabletDown {
    flex-direction: column;
  }

  &__Details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    max-width: 500px;

    &__Section {
      &__Title {
        margin-block-start: 8px;
        margin-block-end: 8px;
        font-weight: bold;
        font-size: 20px;
      }

      &__Content {
        font-size: 14px;
        margin-block-end: 8px;
        margin-block-start: 8px;
        overflow-x: hidden;
      }
    }
  }

  &__Preview {
    box-sizing: border-box;
    position: relative;
    width: 480px;
    height: fit-content;
    aspect-ratio: 16/9;
    background-color: var(--cell-background-color);
    border: 1px solid var(--cell-background-color);
    border-radius: 20px;
    overflow: hidden;

    @include tabletDown {
      width: 100%;
    }

    img {
      width: 100%;
      aspect-ratio: 16/9;
      border-radius: 20px;
    }
  }
}
