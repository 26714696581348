@import "src/sass/utils";

.MobileHeader {
  @include prevent-tab-highlights;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--app-padding-right);
  padding-bottom: var(--app-padding-right);
  padding-left: var(--app-padding-right);
  padding-right: var(--app-padding-right);
  background: linear-gradient(to bottom, var(--header-bg-color), transparent);

  &__Right {
    gap: 1rem;
    display: flex;
    align-items: center;
  }
}
