@import "../../sass/variables";
@import "../../sass/utils";

.IntroVideoScreen {
  &__overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000070;
    z-index: -1;
  }

  &__video {
    position: fixed;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -2;
  }

  .DesktopHeader {
    padding: 0;
    background: none;
  }

  .MobileHeader {
    background: none;
    position: static;
  }

  .HeaderSignIn {
    display: flex;
    justify-content: flex-end;
    margin: 42px 80px 17px 0;
    width: auto;
  }

  .HeaderLogo {
    justify-content: start;

    &__logo {
      height: 6.3rem;
    }
  }

  .AppFooter {
    &__Line {
      display: none;
    }
  }

  &__header-section {
    padding-left: 160px;
    padding-right: var(--app-padding-right);
    margin-top: 120px;
  }

  &__main-section {
    padding-left: 160px;
    padding-right: var(--app-padding-right);
    max-width: 640px;

    @include tabletDown {
      margin-top: 60px;
      padding-left: 30px;
      padding-right: 30px;
    }

    &__title {
      font-size: 48px;
      font-weight: 700;
      margin-block-start: 60px;
      margin-block-end: 24px;
      text-align: left;

      @include tabletDown {
        margin-block-start: 40px;
      }
    }

    &__description {
      font-size: 18px;
      font-weight: 500;
    }

    &__additional-info {
      font-size: 18px;
      font-weight: 500;
      margin-top: 48px;
    }

    &__link {
      text-decoration: underline;
      color: #ffffff;
    }

    .EmailForm {
      display: flex;
      margin-top: 16px;

      @include tabletDown {
        flex-direction: column;
        gap: 30px;
      }

      .LabelField {
        margin-right: 24px;

        @include tabletDown {
          margin-right: 0;
        }
      }

      .Label {
        display: none;
      }

      .Error {
        margin-top: 12px;
      }

      .Input {
        margin: 0;
        height: 48px;
        min-width: 400px;
        padding: 0.8125rem 0 0.8125rem 1.75rem;

        [dir="rtl"] & {
          padding: 0.8125rem 1.75rem 0.8125rem 0;
        }

        @include tabletDown {
          min-width: 0;
        }
      }

      .MediaButton {
        height: 48px;
        min-width: 220px;
        padding: 0.8125rem 3.4rem;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.82px;
        margin: 0;

        &__text {
          margin: 0 auto;
          padding: 0;
        }
      }
    }
  }
}
