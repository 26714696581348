.ArticleDetailsScreen {
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  &__article {
    margin: 7% 10%;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__recommendations {
    padding-top: 0px !important;
  }
}
