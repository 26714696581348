.video-details-section {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 32px;

  &__form {
    flex-basis: 60%;
    flex: 3;
  }

  .FormTextarea {
    width: calc(100% - 2 * 11px);
    min-height: 96px;
  }

  textarea {
    width: 100%;
    background-color: transparent;
    overflow-y: scroll;
    letter-spacing: 0.6px;
    border: none;
    border-radius: 0;
    overflow-y: scroll;
    font-size: 1rem;
  }

  &__video-preview {
    flex-basis: 40%;
    flex: 2;
  }

  .preview-section {
    display: flex;
    flex-direction: column;
  }

  .preview-container {
    background-color: #191919;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    overflow: hidden;
    h4 {
      color: #fff;
      opacity: 0.5;
      font-size: 12px;
    }

    .player-container {
      div {
        width: unset !important;
        height: unset !important;
      }
    }
    .loader-container {
      width: 100%;
      height: 100%;
      min-height: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .broadcast-date-time-section {
    display: flex;
    gap: 30px;
    margin: 16px 0 16px 0;
    margin-top: 30px;

    .available-to-container {
      .available-to-info {
        display: flex;
        flex-direction: row;
        gap: 8px;
        font-size: small;
        color: yellow;
        opacity: 0.8;
      }
    }
  }
  .Error {
    li {
      margin-bottom: 0.5rem;
    }
  }
}

.media-creator-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}
