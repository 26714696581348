@import "src/sass/utils";
@import "src/sass/variables";

.PodcastDetails {
  &__info {
    width: 100%;
    padding-left: 200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;

    [dir="rtl"] & {
      padding-left: 0;
      padding-right: 200px;
    }

    @include tabletDown {
      padding-top: 0;
      padding-left: var(--app-padding-left);
      justify-content: center;

      [dir="rtl"] & {
        padding-right: var(--app-padding-left);
      }
    }

    @include desktop {
      padding-top: 80px;
      padding-left: 150px;

      [dir="rtl"] & {
        padding-right: 150px;
      }
    }

    .MediaStartInfo {
      text-transform: uppercase;
      font-size: 2em;
      letter-spacing: 0.68px;
    }
  }

  &__content {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2rem;

    &--image {
      width: 22vh;
      height: 22vh;

      path {
        fill: currentColor;
      }

      * {
        border-radius: 18px;
      }
    }

    @include phone {
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    padding-bottom: 2px;
    font-size: 48px;
    font-weight: 900;

    @include line-clamp-ellipsis(2);
  }

  &__additional-info {
    font-size: 24px;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    gap: 1rem;

    @include phone {
      justify-content: center;
    }
  }

  &__description {
    font-size: 18px;
    box-sizing: border-box;
    line-height: 22px;
    width: 70%;
    word-break: break-word;
    padding: 0;

    @include tabletDown {
      width: 90%;
    }

    @include phone {
      width: 100%;
      padding: 0 var(--app-padding-right);
    }
  }

  .Rating {
    font-size: 20px;
  }

  .MediaAvailabilityDate {
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 0.68px;
  }

  .MediaAdditionalInfo {
    font-size: 24px;
    font-weight: 400;
  }
}
