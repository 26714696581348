@import "src/sass/variables";

.asset-payment-list {
  list-style: none;
  padding: 0;

  h3 {
    font-size: 16px;
    margin-top: 50px;
  }

  .asset-payment-field {
    display: flex;
    gap: 30px;
    align-items: center;

    .required-select {
      position: relative;
      .required-input-controll {
        position: absolute;
        bottom: -2px;
        opacity: 0;
        height: 0;
        width: 0;
      }
    }
  }

  .price-input {
    border: solid 1px rgba(255, 255, 255, 0.22);
    background-color: transparent;
    font-size: 1rem;
    min-width: 200px;
    margin: 0;
    padding: 0;
    color: $primary;
    outline: 0;
    cursor: pointer;
  }

  .asset-list-add {
    display: flex;
    background: none;
    border: 0;
    color: var(--primary-text-color);
    font-size: 16px;
    align-items: center;
    padding: 0;
    margin-top: 1rem;

    svg {
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      height: 1.5rem;
      width: 1.5rem;
      stroke-width: 2px;
      padding: 0.25rem;
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  .Select-selector {
    .Select-selection-item,
    .Select-selection-placeholder {
      font-size: 1rem;
      text-transform: none;
      left: 16px;
    }
  }

  .remove-button {
    font-size: 2rem;
    background: none;
    border: 0;
    color: var(--primary-text-color);
    margin-top: 24px;
  }
}
