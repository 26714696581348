@import "src/sass/variables";
@import "src/sass/utils";

.SettingsTerms {
  &__Option {
    padding: 2em;
    display: flex;
    justify-content: flex-start;

    label {
      display: flex;
      align-items: center;
    }

    span {
      opacity: 0.87;
      font-size: 1.125rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: 1.38px;
      margin-right: 15px;

      @include phone {
        font-size: 2rem;
      }
    }

    &__Description {
      padding-left: 1em;
    }
  }
}

.RequiredConsent {
  padding-right: 10px;
  color: $error-color;
  font-weight: bold !important;
}

.link {
  opacity: 0.8;
}

.underline {
  text-decoration: underline;
}