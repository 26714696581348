@import "src/sass/utils";

.Metadata {
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;

  @include tabletDown {
    flex-direction: column;
  }

  &__Form {
    flex-grow: 1;
    max-width: 800px;

    .Error {
      li {
        margin-bottom: 0.5rem;
      }
    }

    .FormTextarea {
      width: calc(100% - 2 * 11px);
      min-height: 96px;
    }

    textarea {
      width: 100%;
      background-color: transparent;
      overflow-y: scroll;
      letter-spacing: 0.6px;
      border: none;
      border-radius: 0;
      overflow-y: scroll;
      font-size: 1rem;
    }
  }

  &__Preview {
    &__Container {
      box-sizing: border-box;

      width: 480px;
      aspect-ratio: 16/9;

      @include tabletDown {
        width: 100%;
      }

      background-color: var(--cell-background-color);
      border: 1px solid var(--cell-background-color);
      border-radius: 20px;
      overflow: hidden;

      h4 {
        color: var(--primary-text-color);
        opacity: 0.5;
        font-size: 16px;
      }

      &__Loader {
        width: 100%;
        height: 100%;
        min-height: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &__Player {
        div {
          width: unset !important;
          height: unset !important;
        }
      }
    }
  }
}
