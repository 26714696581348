@import "src/sass/utils";

.PageHeader {
  display: flex;
  margin-bottom: 30px;
  align-items: center;

  &__backIcon {
    svg {
      width: 30px;
      height: 60px;
      margin-top: 5px;
      color: var(--primary-color);
      transform: rotate(90deg);
      cursor: pointer;

      [dir="rtl"] & {
        transform: rotate(-90deg);
        margin-left: 0px;
        margin-right: 12px;
      }
    }
  }

  &__title {
    opacity: 0.87;
    font-size: 2.125rem;
    font-weight: 700;
    letter-spacing: 1.28px;
    margin-left: 10px;
    text-transform: uppercase;

    [dir="rtl"] & {
      margin-right: 18px;
    }
  }
}
