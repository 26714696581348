@import "src/sass/utils";
@import "src/sass/variables";

$offer-height: 54px;

.PaymentConfirmation {
  padding-left: 12.5rem;
  padding-top: 6.25rem;
  padding-right: var(--app-padding-right);

  font-size: 1.5rem;
  [dir="rtl"] & {
    padding-left: var(--app-padding-right);
    padding-right: 12.5rem;
  }

  @include tabletDown {
    padding-left: var(--app-padding-left);

    [dir="rtl"] & {
      padding-right: var(--app-padding-left);
      padding-left: var(--app-padding-right);
    }
  }
  &__main {
    display: flex;
  }

  &__product {
    flex: 0 1 426px;
    margin-right: 25px;
    flex-direction: column;
    align-items: center;

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 25px;
    }

    @include largeDown {
      flex-basis: 292px;
    }

    &__image {
      position: relative;
      padding-top: 150%;
    }
  }

  &__content {
    flex: 1;
    min-width: 250px;
    max-width: 721px;
  }

  &__title {
    opacity: 0.87;
    font-size: 3.75rem;
    font-weight: 900;
    line-height: 1.18;
    color: var(--primary-text-color);
    margin-bottom: 0.625rem;
  }

  &__item-text {
    font-weight: 700;
    margin-bottom: 1rem;
  }
  &__item-title {
    margin-bottom: 1rem;
  }

  &__page-title {
    margin-bottom: 4rem;
  }

  &__coins {
    margin-bottom: 2rem;
    font-weight: 700;
  }
}
