@import "src/sass/variables";

.RegisterForm {
  h1 {
    font-size: $h1-font-size;
    margin-bottom: -5px;
  }

  &__subtitle {
    font-size: 18px;
    margin-top: 35px;
  }
}

.RegisterConsents {
  &__Option {
    padding: 1em 0;
    label {
      display: flex;
      align-items: center;
    }
    &__Description {
      padding-left: 1em;
    }
  }
}
