@import "src/sass/utils";
@import "src/sass/variables";

.media-steps {
  padding-bottom: 50px;
}

//override styles for form inputs

.media-creator {
  padding-left: var(--app-padding-left);
  padding-right: var(--app-padding-right);
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  .step-section {
    flex: 1;
    display: flex;
    flex-direction: column;

    form {
      height: 100%;
    }
  }

  h1 {
    margin-top: 0;
  }

  .rc-steps {
    margin-bottom: 20px;
  }

  .LabelField {
    align-items: flex-start;

    .rc-textarea {
      box-sizing: border-box;
      padding: 0;
      font-family: inherit;

      &:focus {
        outline: none;
      }
    }
  }

  .Calendar__day.-selected {
    background: #ffa726 !important;
  }

  .Select {
    &.categories-select {
      width: 100%;
      padding-top: 18px;
    }
  }
}