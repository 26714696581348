@import "src/sass/utils";
@import "src/sass/variables";

// TODO most of the styles are shared across MovieDetails,
// SeriesDetails, PackageDetails so it might be a good idea to
// create a shared definition
.PackageDetails {
  &__info {
    width: 50%;
    padding-left: 200px;
    margin-bottom: 128px;
    margin-top: 64px;

    @include phone {
      width: 90%;
    }

    @include tablet {
      width: 80%;
    }

    @include tabletDown {
      padding-top: 0;
      padding-left: var(--app-padding-left);
    }

    @include desktop {
      padding-top: 80px;
      padding-left: 150px;
      width: 80%;
    }
  }

  &__type {
    color: var(--primary-color);
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__title {
    opacity: 0.87;
    font-size: 4rem;
    font-weight: 900;
    line-height: 1.01;
    text-transform: uppercase;

    @include line-clamp-ellipsis(2);
  }

  &__actions {
    display: flex;
    font-size: 1rem;
    letter-spacing: 0.0375rem;
    margin: 0 0 44px;

    @include tabletDown {
      flex-wrap: wrap;
    }
  }

  &__description {
    opacity: 0.87;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: 0.0425rem;
    margin: 44px 0 48px;
    width: 75%;

    img{
      width: 100%;
      height: auto;
    }

    @include tabletDown {
      margin: 22px 0 24px;
    }
  }

  &__list--title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 34px;
    letter-spacing: 0.9px;
  }

  &__list-container {
    padding-left: var(--app-padding-left);
    padding-right: var(--app-padding-right);
  }
}
