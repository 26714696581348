.ContactsForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  form {
    width: 100%;
  }
  .ButtonLine {
    width: 100%;
  }

  .FormInput.phone input[type=text] {
      [dir="rtl"] & {
        direction: ltr;
        text-align: right;
      }
  }
  }
