@import "src/sass/utils";
@import "src/sass/theme";

.SectionMenuListComponent .slick-list {
  height: 94px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 150px;

  @include desktopDown {
    height: 100px;
  }
}

.SectionMenuListComponent {
  &:not(:first-of-type) {
    padding-top: var(--app-padding-top);
  }
  padding-left: var(--app-padding-left);
}

.SectionMenuListComponentItem_container {
  background-repeat: no-repeat;

  @include desktopDown {
    background-repeat: no-repeat;
  }
}
