.AudioPlayerPlaybackControls {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1vh;

  &__buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    .MediaButton__icon {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    border-radius: 100%;
    width: 4.5vh;
    height: 4.5vh;

    svg {
      position: absolute;
      min-width: 100%;
      min-height: 100%;
    }

    &--big {
      width: 5.5vh;
      height: 5.5vh;
    }

    &__text {
      font-size: 1.4vh;
      padding: 1vh 0;
    }

    &__icon {
      transition: 0.6s;

      &--backward {
        &:hover {
          transform: rotate(-25deg);
        }
      }

      &--forward {
        &:hover {
          transform: rotate(25deg);
        }
      }
    }
  }

  &--transparent-bg {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }
}