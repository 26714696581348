@import "src/sass/utils";

.HeaderLogo {
  display: flex;
  align-items: center;
  justify-content: center;

  &__logo {
    height: 3.75rem;

    @include phone {
      height: 4.5rem;
    }
  }

  &__default-logo {
    svg {
      height: 3.75rem;

      @include phone {
        height: 4.5rem;
      }
    }
  }
}
