@import "src/sass/utils";

.GridComponent {
  .col {
    display: inline-block;
    box-sizing: border-box;
  }

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ListComponentHorizontal-title:first-child {
      .react-loading-skeleton {
        @include phone {
          width: 10rem !important;
        }
      }
    }
  }

  &__title {
    opacity: 0.87;
    font-size: 2.125rem;
    font-weight: 700;
    letter-spacing: 1.28px;
    text-transform: uppercase;
  }

  &__see-all {
    opacity: 0.87;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.67px;
    text-transform: uppercase;

    display: flex;
    align-items: center;

    img {
      width: 14px;
      transform: rotate(-90deg);
      margin-left: 10px;
    }
  }
}

.GridComponent--loading {
  margin-top: 150px !important;
}
