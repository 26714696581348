@import "src/sass/utils";

.DetectionResults {
  display: flex;
  flex-direction: column;
  padding: 10px;

  &__Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__Empty {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    padding: 20px;
  }

  &__Row {
    margin-block: 5px;
    border-radius: 8px;
    display: flex;
    min-height: 64px;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    &.header {
      background-color: rgba(196, 196, 196, 0.1);
      font-weight: 700;
      font-size: 18px;
    }

    &.result {
      font-weight: 400;
      font-size: 16px;
    }

    &.result:nth-child(2n) {
      background-color: rgba(196, 196, 196, 0.25);
    }

    &.result:nth-child(2n + 3) {
      background-color: rgba(196, 196, 196, 0.2);
    }

    &__Container {
      display: flex;
      flex-direction: row;

      &__Column {
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        color: var(--primary-text-color);
        width: fit-content;
        flex: 2 1 0;

        @include phone {
          font-size: 12px;
          text-align: center;
          justify-content: center;
        }

        &:first-child {
          flex: 2;
        }

        &:last-child {
          flex: 3;
        }

        &__Content {
          flex: 2 1 0;
          height: fit-content;
          padding: 4px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          text-align: center;
        }
      }
    }
  }
}
