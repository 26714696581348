@import "src/sass/utils";
@import "src/sass/variables";

$offer-height: 54px;

.Payment {
  display: flex;
  padding-left: 12.5rem;
  padding-top: 6.25rem;
  padding-right: var(--app-padding-right);

  [dir="rtl"] & {
    padding-left: var(--app-padding-right);
    padding-right: 12.5rem;
  }

  @include tabletDown {
    padding-left: var(--app-padding-left);

    [dir="rtl"] & {
      padding-right: var(--app-padding-left);
      padding-left: var(--app-padding-right);
    }
  }

  &__product {
    flex: 0 1 426px;
    margin-right: 25px;
    flex-direction: column;
    align-items: center;

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 25px;
    }

    @include largeDown {
      flex-basis: 292px;
    }

    &__image {
      position: relative;
      padding-top: 150%;
    }
  }

  &__content {
    flex: 1;
    min-width: 250px;
    max-width: 721px;
  }

  &__title {
    opacity: 0.87;
    font-size: 3.75rem;
    font-weight: 900;
    line-height: 1.18;
    color: var(--primary-text-color);
    margin-bottom: 0.625rem;
  }

  .MediaAdditionalInfo {
    margin-bottom: 0.625rem;
  }

  .Rating {
    margin: 0;
    margin-bottom: 2.5rem;
  }

  .Payment__offers_title {
    opacity: 0.87;
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.68px;
    color: var(--primary-text-color);
    margin-bottom: 1.25rem;
  }

  &__offer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: pointer;
    height: $offer-height;
    border-radius: $offer-height / 2;
    padding: 15px 30px 15px 20px;
    margin-bottom: 1.25rem;
    border: solid 1px rgba(240, 240, 240, 0.43);
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 1px 1px 5px 0px var(--primary-color-d-50);
    }

    &__content {
      display: flex;
      align-items: center;

      .rc-checkbox {
        margin-right: 15px;

        [dir="rtl"] & {
          margin-left: 15px;
          margin-right: 0;
        }
      }
    }

    &__name {
      opacity: 0.87;
      font-size: 1rem;
      line-height: 1.44;
      letter-spacing: 0.6px;
      opacity: 0.87;
      color: var(--primary-text-color);
    }

    &__price {
      opacity: 0.87;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.44;
      letter-spacing: 0.6px;
      opacity: 0.87;
      color: var(--primary-text-color);
      text-align: right;
    }
  }

  &__empty {
    font-size: 3rem;
  }

  &__actions {
    display: flex;
    margin-bottom: 1.875rem;
  }

  &__more {
    opacity: 0.87;
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.68px;
    color: var(--primary-text-color);
    cursor: pointer;
  }

  .MediaButton {
    margin: 0;
    width: auto;
  }
}

.Payment-upgrade-wallet {
  .Dialog-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Dialog {
    height: fit-content;
  }
}
