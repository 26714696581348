@import "src/sass/utils";

$container-padding: 0 130px 24px 150px;
$container-padding-desktop: 0 50px 12px 70px;

.slick-slide {
  .ListComponentAddItemFrame,
  .ListComponentItemFrame,
  .ListComponentItemFrame-container,
  .ListComponentItemCover,
  .ListComponentItemCover-skeleton {
    margin: 0 20px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 20px;
    }
  }
}

.ListComponentHorizontal {
  &:not(:first-of-type) {
    padding-top: var(--app-padding-top);
  }

  &.source-empty {
    padding-top: 0;
  }

  &-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: var(--app-padding-left);
    padding-right: var(--app-padding-right);

    .ListComponentHorizontal-title:first-child {
      .react-loading-skeleton {
        @include phone {
          width: 10rem !important;
        }
      }
    }
  }

  &-title {
    opacity: 0.87;
    font-size: 2.125rem;
    font-weight: 700;
    letter-spacing: 1.28px;
    text-transform: uppercase;
  }

  &-see-all {
    opacity: 0.87;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.67px;
    text-transform: uppercase;

    display: flex;
    align-items: center;

    svg {
      width: 8px;
      height: 16px;
      margin-left: 12px;
      margin-bottom: 2px;
      color: var(--primary-color);

      [dir="rtl"] & {
        transform: scaleX(-1);
        margin-left: 0px;
        margin-right: 12px;
      }
    }
  }

  &-container {
    position: relative;
  }

  &-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  &.ListComponentHighlight,
  &.ListComponentPromo {
    .slick-list {
      overflow: hidden;
    }
  }

  .slider {
    overflow: hidden;
  }

  .slick-list {
    margin: 0 var(--app-padding-right) 0 var(--app-padding-left) !important;
  }

  &.ListComponentHighlight,
  &.ListComponentPromo {
    .slick-list {
      margin: 0 var(--app-padding-right) 0 var(--app-padding-left) !important;
    }
  }
  .ListComponentArrow {
    z-index: 0;
    background: var(--bg-color);
  }
  .slick-track {
    margin-left: 0px;
    margin-right: 0px;
  }

  .slick-dots {
    bottom: 4px;
    text-align: left;
    box-sizing: border-box;
    padding: $container-padding;

    @include tabletDown {
      display: none !important;
    }

    &:before {
      display: block;
      content: "";
      width: 100%;
      display: block;
      height: 0px;
      opacity: 0.4;
      border-top: solid 1px #ffffff;
      margin-bottom: 24px;
    }

    & > li,
    & > li > button {
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;
    }

    & > li > button:before {
      position: relative;
      display: inline-block;
      opacity: 0.6;
      width: 8px;
      height: 8px;
      padding: 0;
      margin: 4px;
      border-radius: 50%;
      font-size: 0;
      border: 0;
      cursor: pointer;
      background-color: var(--primary-text-color);
    }

    .slick-active > button:before {
      opacity: 1;
      background-color: var(--secondary-color);
    }
  }

  &.ListComponentHighlightsWidescreen {
    .slick-list {
      margin: 0 !important;
    }
    &:first-of-type {
      margin-top: -164px;

      @include tabletDown {
        margin-top: -114px;
      }
      @include phone {
        margin-top: -114px;
      }
    }

    .slick-dots {
      bottom: 4px;
      text-align: right;
      box-sizing: border-box;
      padding: 0 43px 0 150px;
      margin-bottom: 59px;

      &:before {
        content: none;
      }

      & > li > button:before {
        width: 27px;
        border-radius: 20px;
        transition: all 0.2s ease-in-out;
      }

      .slick-active > button:before {
        background-color: var(--primary-text-color);
        width: 53px;
        border-radius: 20px;
      }
    }
  }
}

.load-item-spinner {
  position: relative;
  width: 350px;
  height: 200px;
  display: inline-block;
  margin-right: 2px;

  .load-item-spinner-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
