.ListComponentItem {
  position: relative;
  img {
    border-radius: 5px;
  }

  [dir="rtl"] & {
    direction: rtl;
  }
}
