@import "src/sass/utils";
@import "src/sass/variables";

.MediaButton {
  @include prevent-tab-highlights;
  font-weight: 500;
  margin-top: 1em;
  margin-right: 1em;
  height: $button-height-default;
  font-size: $button-font-default;
  font-family: var(--font-family);
  object-fit: contain;
  border-radius: 27px;
  color: var(--primary-text-color);
  border: 1px solid var(--primary-text-color);
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0;
  vertical-align: middle;
  outline: none;
  box-shadow: black 0 3px 10px;

  [dir="rtl"] & {
    margin-right: 0;
    margin-left: 1em;
  }

  &__icon,
  &__LoaderSpinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $button-height-default;
    height: $button-height-default;
    border-radius: 50%;

    + .MediaButton__text {
      padding-left: 0;
    }
  }

  &__icon--elevated {
    + .MediaButton__text {
      padding-left: 10px;

      [dir="rtl"] & {
        padding-left: 22px;
        padding-right: 10px;
      }
    }
  }

  &__text {
    padding: 0 1em;
    [dir="rtl"] & {
      padding-left: 22px;
      padding-right: 10px;
    }
  }

  &--primary {
    border-color: var(--primary-color);
    background-color: var(--primary-color);

    .MediaButton__icon--elevated {
      box-shadow: 0 0 20px 0 var(--primary-color-d-50);
    }

    .MediaButton__LoaderSpinner {
      svg {
        circle {
          stroke: var(--primary-text-color);
        }
      }
    }
  }

  &--secondary {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);

    .MediaButton__icon--elevated {
      box-shadow: 0 0 20px 0 var(--secondary-d-50);
    }
  }

  &--link {
    border-color: var(--link-color);
    background-color: var(--link-color);

    .MediaButton__icon--elevated {
      box-shadow: 0 0 20px 0 var(--link-d-50);
    }
  }

  &--transparent {
    border-color: rgba(55, 55, 55, 0.4);
    background-color: rgba(55, 55, 55, 0.4);

    .MediaButton__icon--elevated {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
    }
  }

  &--disabled {
    border-color: #5a5a5a;
    background-color: #5a5a5a;
    color: #8f8f8f;
    cursor: not-allowed;

    .MediaButton__icon--elevated {
      box-shadow: 0 0 20px 0 #5a5a5a;
    }
  }

  &--loading {
    opacity: 0.6;
  }

  &--unfilled {
    background-color: transparent;
    border-width: 2px;
    border-color: white;

    .MediaButton__icon {
      fill: white;
    }

    .MediaButton__icon--elevated {
      box-shadow: 0 0 20px 0 var(--primary-color-d-50);
    }

    .MediaButton__LoaderSpinner {
      svg {
        circle {
          stroke: var(--primary-text-color);
        }
      }
    }
  }
}

@include phone {
  .MediaButton {
    height: $button-height-sm;

    &__icon {
      width: $button-height-sm;
      height: $button-height-sm;
    }
  }
}

@include tablet {
  .MediaButton {
    height: $button-height-md;

    &__icon {
      width: $button-height-md;
      height: $button-height-md;
    }
  }
}

@include desktop {
  .MediaButton {
    height: $button-height-md;

    &__icon {
      width: $button-height-md;
      height: $button-height-md;
    }
  }
}

@include large {
  .MediaButton {
    height: $button-height-md;

    &__icon {
      width: $button-height-md;
      height: $button-height-md;
    }
  }
}
