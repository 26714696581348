@import "src/sass/variables";

.SettingsPreferences {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 2vw;
  form {
    width: 60%;

    Button {
      margin-top: 30px;
    }
  }

  .ButtonLine {
    width: 100%;
  }

  .Select {
    width: 100%;
  }
}
