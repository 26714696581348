@import "src/sass/utils";

.AudioPlayerAdditionalControls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  gap: 2.5rem;

  @include phone {
    gap: 1rem;
  }



  &__button {
    margin: 0;
    width: 2vh;
    height: 2vh;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    transition: 0.2s;

    &--left {
      margin-right: 25px;
    }

    &--right {
      margin-left: 25px;
    }

    &:hover {
      transform: scale(1.2);
      color: #ffffff;
    }

    &--close-button {
      transform: rotate(45deg);
      color: #bdbdbd;
      line-height: 1;
      height: 7vh;
      width: 7vh;
      font-size: 7vh;
      text-align: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        transform: scale(1.2) rotate(45deg);
      }
    }

    &--large-text {
      .MediaButton__text {
        font-size: 2vh !important;
        position: absolute !important;
        padding: 0 !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
      }
    }

    .MediaButton__text {
      position: absolute;
      padding: 0 !important;
      font-size: 12px;
      margin-left: 9px;
      margin-top: 5px;

      @media screen and (max-width: 1399px) {
        margin-left: 10px;
        margin-top: 5px;
        font-size: 10px;
      }
    }
  }

  &--transparent-bg {
    background-color: transparent;
    border-color: transparent;
  }

  &--shrink80 {
    transform: scale(0.8);
    &:hover {
      transform: scale(1);
    }
  }

  .MediaButton__icon {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;

    svg {
      width: 2.5vh;
      height: 2.5vh;
    }
  }
}
