@import "src/sass/utils";

.ListComponentAddItemFrame {
  transition: all 0.2s;

  &__container {
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background: transparent;
    border: 2px solid var(--primary-color);

    &:hover, &:focus {
      background: var(--focus-color);
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 56.25%;
  }

  &__upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    &-title {
      overflow: hidden;
      opacity: 0.87;
      font-size: 1.5rem;
      font-weight: 700;
      letter-spacing: 0.9px;
    }

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
    }
  }
}
