@import "src/sass/utils";
@import "src/sass/variables";

// TODO most of the styles are shared across MovieDetails,
// SeriesDetails, PackageDetails so it might be a good idea to
// create a shared definition
.MovieDetails {
  &__info {
    width: 90%;
    padding-top: 150px;
    padding-left: 200px;
    box-sizing: border-box;
    [dir="rtl"] & {
      padding-right: 200px;
      padding-left: 0;
    }

    @include tabletDown {
      padding-top: 0;
      padding-left: var(--app-padding-left);

      [dir="rtl"] & {
        padding-right: var(--app-padding-left);
      }
    }

    @include desktop {
      padding-top: 80px;
      padding-left: 150px;

      [dir="rtl"] & {
        padding-right: 150px;
      }
    }

    .MediaStartInfo {
      text-transform: uppercase;
      font-size: 2em;
      letter-spacing: 0.68px;
    }
  }

  &__title {
    padding-bottom: 2px;
    opacity: 0.87;
    font-size: 4rem;
    font-weight: 900;
    line-height: 1.01;

    @include line-clamp-ellipsis(2);
  }

  .MediaAdditionalInfo {
    margin: 10px 0;
  }

  .MediaEpisodeInfo {
    margin: 5px 0;
    font-size: 1rem;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    padding-block: 25px;
    gap: 15px;

    &__buttons {
      display: flex;
      gap: 15px;

      @include tabletDown {
        flex-direction: column;
      }
    }

    &__info {

    }
  }

  &__description {
    opacity: 0.87;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: 0.0425rem;
    margin: 44px 0 48px;
    width: 100%;
    word-break: break-word;

    img {
      width: 100%;
      height: auto;
    }

    @include tabletDown {
      margin: 22px 0 24px;
    }
  }

  .Rating {
    font-size: 20px;
  }

  .MediaAvailabilityDate {
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 0.68px;
  }
}
