@import "src/sass/utils";

$black: #000;
$white: #fff;

$padding-lg: 24px; // containers

$font-size-base: 0.75rem;
$font-size-lg: 0.875rem;

$zindex-modal: 1000;
$zindex-modal-mask: 1000;
$zindex-popup-close: 10;

$animation-duration-slow: 0.3s;
$ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);

$shadow-1-left: -6px 0 16px -8px rgba(0, 0, 0, 0.08),
  -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
$shadow-1-up: 0 -6px 16px -8px rgba(0, 0, 0, 0.32),
  0 -9px 28px 0 rgba(0, 0, 0, 0.2), 0 -12px 48px 16px rgba(0, 0, 0, 0.12);
$shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.32),
  0 9px 28px 0 rgba(0, 0, 0, 0.2), 0 12px 48px 16px rgba(0, 0, 0, 0.12);
$shadow-1-right: 6px 0 16px -8px rgba(0, 0, 0, 0.32),
  9px 0 28px 0 rgba(0, 0, 0, 0.2), 12px 0 48px 16px rgba(0, 0, 0, 0.12);
$shadow-2: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32),
  0 9px 28px 8px rgba(0, 0, 0, 0.2);

$heading-color: rgba($white, 0.85);

$modal-mask-bg: rgba($black, 0.45);

$drawer-header-padding: 70px 40px 0 70px;
$drawer-body-padding: 0 40px 0 70px;
$drawer-bg: #121212;
$drawer-footer-padding-vertical: 10px;
$drawer-footer-padding-horizontal: 16px;

.drawer {
  position: fixed;
  z-index: $zindex-modal;
  width: 0%;
  height: 100%;
  transition: transform $animation-duration-slow $ease-base-out,
    height 0s ease $animation-duration-slow,
    width 0s ease $animation-duration-slow;
  > * {
    transition: transform $animation-duration-slow $ease-base-out,
      box-shadow $animation-duration-slow $ease-base-out;
  }

  &-content-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .drawer-content {
    width: 100%;
    height: 100%;
  }

  &-left,
  &-right {
    top: 0;
    width: 0%;
    height: 100%;
    .drawer-content-wrapper {
      height: 100%;

      @include tabletDown {
        width: 90% !important;
      }
    }
    &.drawer-open {
      width: 100%;
      transition: transform $animation-duration-slow $ease-base-out;
    }
  }

  &-left {
    left: 0;

    .drawer {
      &-content-wrapper {
        left: 0;
      }
    }

    &.drawer-open {
      .drawer-content-wrapper {
        box-shadow: $shadow-1-right;
      }
    }
  }

  &-right {
    right: 0;

    .drawer {
      &-content-wrapper {
        right: 0;
      }
    }
    &.drawer-open {
      .drawer-content-wrapper {
        box-shadow: $shadow-1-left;
      }
      // Avoid edge alignment bug.
      &.no-mask {
        right: 1px;
        transform: translateX(1px);
      }
    }
  }

  &-top,
  &-bottom {
    left: 0;
    width: 100%;
    height: 0%;

    .drawer-content-wrapper {
      width: 100%;
    }
    &.drawer-open {
      height: 100%;
      transition: transform $animation-duration-slow $ease-base-out;
    }
  }

  &-top {
    top: 0;

    &.drawer-open {
      .drawer-content-wrapper {
        box-shadow: $shadow-1-down;
      }
    }
  }

  &-bottom {
    bottom: 0;

    .drawer {
      &-content-wrapper {
        bottom: 0;
      }
    }
    &.drawer-open {
      .drawer-content-wrapper {
        box-shadow: $shadow-1-up;
      }
      &.no-mask {
        bottom: 1px;
        transform: translateY(1px);
      }
    }
  }

  &.drawer-open {
    .drawer {
      &-mask {
        height: 100%;
        opacity: 1;
        transition: none;
        animation: fadeInAnimation $animation-duration-slow $ease-base-out;
      }
    }
  }

  // &-title {
  //   margin: 0;
  //   color: $heading-color;
  //   font-weight: 500;
  //   font-size: $font-size-lg;
  //   line-height: 22px;
  // }

  &-content {
    position: relative;
    z-index: 1;
    overflow: auto;
    background-color: $drawer-bg;
    background-clip: padding-box;
    border: 0;
  }

  &-close {
    position: absolute;
    top: -15px;
    left: -25px;
    transform: translateX(-50%);
    z-index: $zindex-popup-close;
    display: block;
    font-weight: 700;
    font-size: $font-size-lg;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;
    transition: color $animation-duration-slow;
    text-rendering: auto;
    box-shadow: none;

    &:focus,
    &:hover {
      text-decoration: none;
    }

    &-icon {
      font-size: 22px;
      color: var(--primary-color);
    }
  }

  &-header {
    position: relative;
    margin: $drawer-header-padding;
  }

  // &-header-no-title {
  //   color: $text-color;
  //   background: $drawer-bg;
  // }

  &-wrapper-body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
  }

  &-body {
    flex-grow: 1;
    padding: $drawer-body-padding;
    overflow: auto;
    word-wrap: break-word;
  }

  // &-footer {
  //   flex-shrink: 0;
  //   padding: $drawer-footer-padding-vertical $drawer-footer-padding-vertical;
  //   border-top: $border-width-base $border-style-base $border-color-split;
  // }

  &-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $modal-mask-bg;
    opacity: 0;
    filter: alpha(opacity=45);
    transition: opacity $animation-duration-slow linear,
      height 0s ease $animation-duration-slow;
  }

  &-open {
    &-content {
      box-shadow: $shadow-2;
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
