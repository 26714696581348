@import "src/sass/utils";

.Images {
  &__Container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 64px;

    @include tabletDown {
      flex-direction: column;
    }

    &__Item {
      display: flex;
    }
  }
}
