.SampleUploader {
  padding: 10px;

  .Label {
    display: none;
  }

  .LabelField .Error {
    margin: 0;
  }

  .upload-file-container {
    margin-block: 5px;
    height: 150px;
  }

  .upload {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

}
