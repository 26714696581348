@import "src/sass/utils";
@import "src/sass/variables";

$container-padding: 0 130px 0 150px;
$container-padding-desktop: 0 50px 0 70px;
$container-padding-tablet: 0 50px 0 70px;
$container-padding-phone: 0 20px 0 40px;

.ListComponentItemHighlight {
  position: relative;
  margin: 0;
  padding: 0;
  background: var(--cell-background-color);
  border-radius: 5px;

  &--skeleton {
    padding-top: 37.5%;

    @include phone {
      padding-top: 50%;
    }
  }

  &__Container {
    min-width: 80%;
    position: absolute;
    bottom: 100px;
    box-sizing: border-box;
    padding: $container-padding;

    @include phone {
      padding: $container-padding-phone;
      bottom: 0 !important;
      height: 100%;
    }

    @include tablet {
      padding: $container-padding-tablet;
    }

    @include tabletDown {
      width: 100%;
      bottom: 30px;
    }

    @include desktop {
      width: 100%;
      padding: $container-padding-tablet;
      bottom: 90px;
    }

    @include large {
      padding: $container-padding-tablet;
    }
  }

  &__Content {
    min-width: 80%;

    @include tabletDown {
      min-width: 100%;
    }

    @include desktop {
      min-width: 80%;
    }

    @include phone {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  &__Title {
    min-width: 100%;
    opacity: 0.87;
    font-size: 3.6rem;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.01;
    letter-spacing: normal;
    padding-bottom: 8px;
    margin: 0 0 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include tabletDown {
      white-space: pre-line;
      font-size: 2.8rem;
      padding-bottom: 2px;
    }

    @include desktopDown {
      -webkit-line-clamp: 2;
    }

    @include phone {
      text-align: center;
    }
  }

  &__Description {
    opacity: 0.8;
    width: 70%;
    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: 0.68px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 96px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    @include phone {
      text-align: center;
    }
  }

  &__ActionButtons {
    @include phone {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__Skeleton {
    position: absolute;
    width: 70%;
    bottom: 150px;
    left: 150px;

    [dir="rtl"] & {
      right: 150px;
    }

    @include phone {
      width: 100%;
      bottom: -22px;
      left: 112px;
    }
  }

  .MediaAvailabilityDate {
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 0.68px;
  }

  .MediaStartInfo {
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 0.68px;
  }

  .ImageWithRatio {
    border-radius: 5px;
  }
}
