@import "src/sass/variables";
@import "src/sass/utils";

.AuthForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  form,
  .form {
    width: 40%;

    @include tabletDown {
      width: 100%;
    }

    @include desktop {
      width: 70%;
    }
  }

  .ButtonLine {
    width: 100%;
    text-align: center;
  }

  h1 {
    font-size: $h1-font-size;
  }
}
