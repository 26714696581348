@import "src/sass/utils";

.AuthScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  section {
    flex: 1;
    padding-left: calc(var(--app-padding-left) / 2);
    padding-right: calc(var(--app-padding-left) / 2);
  }

  @include phone {
    section {
      padding-left: var(--app-padding-left);
      padding-right: var(--app-padding-left);
    }
  }
}

.Version {
  position: fixed;
  bottom: 2%;
  right: 2%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 4px;

  span {
    font-size: 10px;
    margin-left: 4px;
    color: #727272;
  }
}
