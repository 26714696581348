@import "src/sass/utils";
@import "src/sass/variables";

.MediaDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  section {
    flex: 1 0 80vh;

    @include phone {
      margin-top: 12vh;
    }
  }

  &__background {
    position: absolute;
    pointer-events: none;
    user-select: none;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;

    .ImageOverlay-container {
      position: relative;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__back {
    @include prevent-tab-highlights;
    position: absolute;
    top: 115px;
    z-index: 2;
    left: var(--app-padding-left);
    cursor: pointer;
    [dir="rtl"] & {
      right: var(--app-padding-right);
    }

    svg {
      width: 24px;
      height: 24px;
      transform: rotate(0.5turn);
      color: var(--primary-color);
      [dir="rtl"] & {
        transform: rotate(0turn);
      }
    }

    @include tabletDown {
      top: 160px;
      left: calc(var(--app-padding-left) / 3);
    }

    @include phone {
      top: 64px;
      left: var(--app-padding-left);
      z-index: 1001;
    }
  }

  .MediaButton {
    white-space: nowrap;
  }
}
