@import "src/sass/utils";

$container-padding: 0 100px 50px 60px;
$container-padding-tablet: 0 20px 12px 30px;
$container-padding-phone: 0 20px 12px 15px;

.ListComponentItemPromo {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);

  &__overlapping-cover-box {
    width: 2px;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    background-color: var(--bg-color);
  }

  &--skeleton {
    display: flex;
  }

  &__Background {
    position: relative;
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;

    @include phone {
      flex-direction: column;
    }
  }

  &__Image {
    flex: 55 1 55%;

    @include phone {
      filter: brightness(0.5);
    }

    &--skeleton {
      border: 1px solid var(--cell-background-color);
      box-sizing: border-box;
      padding-top: 31.25%;

      @include phone {
        display: none;
      }
    }
  }

  &__ContainerBackgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    -moz-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    zoom: 2;
  }

  &__ContainerBackground {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__Container {
    position: relative;
    display: flex;
    align-items: center;
    flex: 45 1 45%;

    &--skeleton {
      background: var(--cell-background-color);
      border: solid 1px #212121;
      border-left: none;
      box-sizing: border-box;

      @include phone {
        padding-top: 50%;
      }
    }
  }

  &__Content {
    position: absolute;
    padding: 0 40px 50px 60px;

    @include phone {
      padding: $container-padding-phone;
    }

    @include tablet {
      padding: $container-padding-tablet;
    }

    @include desktop {
      padding: $container-padding-tablet;
    }

    @include large {
      padding: $container-padding-tablet;
    }
  }

  &__Datetime {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-bottom: 8px;
    color: var(--primary-color);
  }

  &__Title {
    opacity: 0.87;
    font-size: 3rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.25px;

    @include ellipsis();
    @include line-clamp-ellipsis(2);

    @include tabletDown {
      margin: 0;
      font-size: 1.5rem;
    }

    @include desktopDown {
      font-size: 2rem;
    }

    @include phone {
      font-size: 2rem;
    }
  }

  &__Description {
    opacity: 0.8;
    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: 0.68px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 100px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &__ActionButtons {
    @include phone {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__Skeleton {
    position: absolute;
    width: 100%;
    top: 55%;
    left: 50px;
    transform: translateY(-50%);

    [dir="rtl"] & {
      right: 50px;
    }

    @include phone {
      top: 50%;
    }
  }
}
