@import "src/sass/utils";

.ListComponentItemFrame {
  transition: all 0.2s;

  &__container {
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background: var(--cell-background-color);

    &-skeleton {
      padding-top: 56.25%;
      margin-left: 20px;

      @include phone {
        padding-top: 100%;
      }
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 56.25%;
  }

  &__overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.93)
    );
  }

  &__processing {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background: rgba(0, 0, 0, 0.6);
    pointer-events: none;

    &-title {
      overflow: hidden;
      font-size: 1.5rem;
      font-weight: 700;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
    width: 100%;

    &--hidden {
      opacity: 0;
    }
  }

  &__title-container {
    padding: 20px;
  }

  &__title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    width: 80%;
    display: -webkit-box;
    word-wrap: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    opacity: 0.87;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.9px;
  }

  &__info-trigger {
    transition: all 0.2s;
    cursor: pointer;
    font-size: 1.5rem;

    svg {
      display: block;
    }
  }

  &__info {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.2s;

    &--visible {
      opacity: 1;
    }
  }

  &__skeleton {
    position: absolute;
    width: 100%;
    bottom: 26px;
    left: 20px;

    [dir="rtl"] & {
      right: 20px;
    }
  }

  &__info-trigger,
  &__play-button {
    opacity: 0;
  }

  &:hover {
    .ListComponentItemFrame__overlay,
    .ListComponentItemFrame__info-trigger,
    .ListComponentItemFrame__play-button {
      opacity: 1;
    }
  }

  .ProgressBar-container {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.11) !important;
    height: 7px;

    .ProgressBar-bar {
      border-radius: 3.5px;
    }
  }

  .Uppercase {
    text-transform: uppercase;
  }
}
