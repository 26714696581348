@import "src/sass/utils";

.TokenPaymentInfo {
  padding-left: 160px;
  padding-right: 160px;
  padding-top: 280px;
  font-size: 18px;
  @include tabletDown {
    padding-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
  }

  &__title {
    text-transform: uppercase;
    font-size: 32px;
    margin-bottom: 24px;
  }

  &__steps {
    display: grid;
    width: 100%;
    margin-top: 83px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    column-gap: 24px;
    @include phone {
      grid-template-columns: 1fr;
    }
  }

  &__step-mobile {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__step-title {
    display: flex;
    align-items: flex-start;
    margin-top: 32px;
    margin-bottom: 10px;

    @include phone {
      justify-content: center;
    }
  }

  &__number {
    height: 50px;
    width: 50px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 1rem;
    font-weight: 900;
    border: 2px var(--primary-color) solid;
  }

  &__text {
    width: 60%;
    color: var(--primary-color);
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    @include desktop {
      max-width: 190px;
    }
  }

  &__image {
    width: 100px;
    height: 100px;
  }

  &__link {
    text-decoration: underline;
    color: #ffffff;
  }

  .text-center {
    text-align: center;
  }
}
