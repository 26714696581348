@import "src/sass/utils";

.ImageOverlay {
  &-container {
    background-color: transparent;
    position: absolute;
    width: 100%;
    max-height: 1600px;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;

    div {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-overlay {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
}
