@import "src/sass/utils";
@import "src/sass/variables";

.SectionMenuListComponentItem {
  height: 63px;
  width: max-content;
  border-radius: 56px;
  background-color: transparent;
  border: 0;
  color: var(--primary-text-color);
  font-weight: 400;
  font-size: 40px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0 20px;

  @include desktopDown {
    height: 60px;
    border: 2px solid var(--primary-text-color);
    border-radius: 56px;
    font-weight: 300;
    font-size: 26px;
    margin-right: 20px;
  }
}

.SectionMenuListComponentItem:hover {
  background-color: rgba(238, 238, 238, 0.2);
  cursor: pointer;
}
