.WizardFooter {
  height: 300px;
  width: 100%;
  max-width: 100vw;

  &__Container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100vw - 8px);
    height: 120px;
    background-color: var(--bg-color);
    z-index: 100;
    padding-bottom: 50px;

    &__Actions {
      margin: 0 3vw;
      display: flex;
      gap: 16px;
      justify-content: center;

      button {
        max-width: 200px;
      }

      &.previous-button {
        background-color: var(--bg-color);
        border: 2px solid #979797;
      }
    }
  }

  .FormLine {
    margin: 0 3vw;
    width: 94vw;
  }
}

