.PlaylistScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__loader {
    display: flex;
    justify-content: center;
  }

  section {
    flex: 1;
    padding-left: var(--app-padding-left);
    padding-right: var(--app-padding-right);
  }
}
