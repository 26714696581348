@import "src/sass/utils";

.ListComponentItemRound {
  transition: all 0.2s;
  display: block;
  position: relative;
  margin: 5px;
  aspect-ratio: 1/1;

  &__container {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: var(--cell-background-color);
    border-radius: 100%;
    aspect-ratio: inherit;
    width: 100%;
    height: 100%;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.93)
    );
  }

  &__image-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
    }
  }

  &__skeleton {
    position: absolute;
    width: 100%;
    bottom: 26px;
    left: 60px;

    [dir="rtl"] & {
      right: 60px;
    }

    &-container {
      background: var(--cell-background-color);
      margin: 5px;
      border-radius: 5px;
      border-radius: 1000px;
    }
  }

  &__play-button {
    opacity: 0;
  }

  &:hover {
    .ListComponentItemRound__overlay,
    .ListComponentItemRound__play-button {
      opacity: 1;
    }
  }
}
