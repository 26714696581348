.AudioSpeedButton {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  &__choice {
    margin: 5px;
    &-container {
      display: flex;
      flex-direction: column;
      background: var(--bg-color);
    }
    &--inactive {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
    .MediaButton__text {
      margin: auto;
      color: var(--primary-text-color);
    }
  }
  &__overlay {
    .rc-tooltip-inner {
      padding: 0;
    }
  }
  .MediaButton__text {
    margin: auto;
    color: #B6B7BC;
  }
}
