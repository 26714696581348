// Fix to pass Lighthouse and still not display labels for some fields
@import "src/sass/utils";

body {
  overflow-y: auto;
}

.label--hidden {
  position: absolute;
  top: -10000px;
  left: -10000px;
}
