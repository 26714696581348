$select-border: solid 1px rgba(255, 255, 255, 0.22);
$select-font-size: 16px;
$select-letter-spacing: 0.9px;
$select-text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
$select-opacity: 0.87;

.LanguageSelect {
  display: inline-flex;
  position: relative;
  font-size: $select-font-size;
  font-weight: 700;
  letter-spacing: $select-letter-spacing;
  text-shadow: $select-text-shadow;
  min-width: 100px;
  height: max-content;
}

.LanguageSelect-disabled {
  opacity: 0.3;

  input {
    cursor: not-allowed;
  }
}

.LanguageSelect-single {
  cursor: pointer;
  height: max-content;

  .LanguageSelect-selector {
    display: flex;
    position: relative;
    cursor: pointer;
    height: max-content;

    .LanguageSelect-selection-search,
    .LanguageSelect-selection-search-input {
      width: 0;
      cursor: pointer;
      height: max-content;
    }
  }
}

.LanguageSelect-selection-item,
.LanguageSelect-selection-placeholder {
  padding-right: 16px;
  pointer-events: none;
  opacity: $select-opacity;
}

.LanguageSelect-selector {
  padding: 0px 24px 0px 8px;
  height: max-content;
  width: 100%;
}

.LanguageSelect-show-arrow .LanguageSelect-arrow {
  pointer-events: none;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  align-items: center;
  justify-content: center;

  svg {
    transition: transform 0.2s;
    display: block;
    height: 100%;
    width: 32px;
  }
}

.LanguageSelect-open .LanguageSelect-arrow {
  svg {
    transform: rotate(-0.5turn);
  }
}

.LanguageSelect-dropdown {
  border: $select-border;
  position: absolute;
  background-color: var(--bg-color);
  box-sizing: border-box;
  font-size: $select-font-size;
  letter-spacing: $select-letter-spacing;
  text-shadow: $select-text-shadow;
  border-radius: 10px;

  &-hidden {
    display: none;
  }
}

.LanguageSelect-item {
  padding: 8px 24px 8px 16px;
  cursor: pointer;
}

.LanguageSelect-item-option {
  position: relative;
}

.LanguageSelect-item-option-active {
  text-decoration: underline;
}

.LanguageSelect-item-option-content {
  opacity: $select-opacity;
}

.LanguageSelect-item-option-disabled {
  color: #999;
}

.LanguageSelect-item-empty {
  text-align: center;
}
