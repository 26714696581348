.PlayButtonForm {
  display: flex;
  flex-direction: column;

  .LabelField .Label {
    margin: 0;
  }

  .Select {
    width: 100%;
  }

  .Select {
    &.FormInputError {
      margin-top: 0 !important;
      height: inherit !important;
      padding: 0 !important;
    }

    .Select-selector {
      border-radius: 30px;
    }
  }

}
